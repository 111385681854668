<template lang="html">
  <AdministrationLayout>
    <template #header>
      <IxRes>administration.dashboard.header</IxRes>
    </template>

    <label>frontend config</label>
    <code>
      <pre>{{ JSON.stringify(config, null, 2) }}</pre>
    </code>
  </AdministrationLayout>
</template>

<script>
import {config} from 'src/js/infrastructure'

import AdministrationLayout from './components/AdministrationLayout'

export default {
  components: {
    AdministrationLayout
  },
  computed: {
    config () {
      return config
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
